.search_wrapp {
  position: relative; }

.block_search {
  margin-left: 24px;
  float: right; }

@media (max-width: 767px) and (min-width: 320px) {
  .block_search {
    display: inline-block;
    margin-left: auto;
    float: none; } }

#search_block_top {
  position: static;
  padding: 0; }

#search_block_top #searchbox, #search_block_top:before {
  position: absolute;
  visibility: hidden;
  transition: .4s all ease; }

#search_block_top:before {
  opacity: 0;
  content: '';
  background: rgba(255, 255, 255, 0.7);
  top: 1px;
  right: 0;
  bottom: 1px;
  left: 0;
  z-index: 998; }

@media (max-width: 767px) {
  #search_block_top:before {
    background: #fff;
    top: -8px;
    bottom: -2px; } }

#search_block_top #search-toggle-button {
  display: inline-block;
  padding-top: 1px;
  transition: .3s all ease;
  cursor: pointer; }

@media (min-width: 900px) {
  #search_block_top #search-toggle-button {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cpath class='st0' d='M17.1,1.4c-8.6,0-15.7,7-15.7,15.7s7,15.7,15.7,15.7c3.8,0,7.2-1.3,9.9-3.5l9.4,9.4c0.6,0.6,1.6,0.6,2.2,0 s0.6-1.6,0-2.2l0,0L29.2,27c2.2-2.7,3.5-6.2,3.5-9.9C32.7,8.4,25.7,1.4,17.1,1.4z M17.1,4.5c6.9,0,12.5,5.6,12.5,12.5 S24,29.6,17.1,29.6S4.5,24,4.5,17.1S10.1,4.5,17.1,4.5z'/%3E%3C/svg%3E");
    width: 22px;
    height: 22px;
    position: relative;
    top: 8px; } }

@media (max-width: 767px) and (min-width: 320px) {
  #search_block_top #search-toggle-button {
    width: 30px;
    height: 30px;
    background-image: url(../../../mobile/img/search_icon.svg);
    position: absolute;
    top: 19px;
    right: 82px; } }

#search_block_top #search-toggle-button:hover, #search_block_top.active-search #searchbox .button-search:hover, #search_block_top.active-search #searchbox .search-close:hover {
  color: #d51521; }

#search_block_top #search-toggle-button:hover,
#search_block_top #search-toggle-button:focus {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%); }

#search_block_top #searchbox {
  left: 17.5%;
  right: 17.5%;
  top: 50%;
  bottom: auto;
  margin-top: -30px;
  padding: 0;
  float: none;
  width: auto;
  z-index: 999; }

@media (max-width: 767px) and (min-width: 320px) {
  #search_block_top #searchbox {
    margin-top: -35px; } }

#search_block_top #searchbox #search_query_top, .ac_results {
  background: #fff;
  text-align: left;
  box-shadow: 0 3px 27px 0 rgba(0, 0, 0, 0.05); }

#search_block_top #searchbox #search_query_top {
  display: block;
  color: #5c5d65;
  height: 60px;
  font-size: 13px;
  margin: 0 auto;
  transition: .3s all ease;
  opacity: 0;
  width: 0;
  border: none;
  padding: 0; }

@media (max-width: 767px) {
  #search_block_top #searchbox #search_query_top {
    box-shadow: 0 1px 10px #a7a7a7, inset 0 1px 0 #fff; } }

#search_block_top #searchbox .button-search {
  position: absolute;
  top: 52%;
  right: 21px;
  background: 0 0;
  border: none;
  transform: translate(0, -50%);
  opacity: 0; }

@media (max-width: 767px) and (min-width: 320px) {
  #search_block_top #searchbox .button-search {
    top: 28%; } }

@media (min-width: 900px) {
  #search_block_top #searchbox .button-search:before {
    font-family: "FontAwesome";
    content: "\f002";
    display: inline-block;
    font-weight: 400;
    font-size: 20px; } }

@media (max-width: 767px) and (min-width: 320px) {
  #search_block_top #searchbox .button-search:before {
    content: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cpath class='st0' d='M17.1,1.4c-8.6,0-15.7,7-15.7,15.7s7,15.7,15.7,15.7c3.8,0,7.2-1.3,9.9-3.5l9.4,9.4c0.6,0.6,1.6,0.6,2.2,0 s0.6-1.6,0-2.2l0,0L29.2,27c2.2-2.7,3.5-6.2,3.5-9.9C32.7,8.4,25.7,1.4,17.1,1.4z M17.1,4.5c6.9,0,12.5,5.6,12.5,12.5 S24,29.6,17.1,29.6S4.5,24,4.5,17.1S10.1,4.5,17.1,4.5z'/%3E%3C/svg%3E");
    width: 22px;
    height: 22px;
    position: absolute; } }

#search_block_top #searchbox .search-close {
  position: absolute;
  top: 50%;
  left: -30px;
  cursor: pointer;
  color: #2f2c2f;
  width: 30px;
  height: 30px;
  text-align: center;
  transform: translate(0, -50%);
  transition: .1s all ease;
  opacity: 0; }

@media (min-width: 900px) {
  #search_block_top #searchbox .search-close:before {
    font-family: "FontAwesome";
    content: "\f00d";
    display: inline-block;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px; } }

@media (max-width: 767px) and (min-width: 320px) {
  #search_block_top #searchbox .search-close:before {
    content: " ";
    width: 21px;
    height: 20px;
    position: absolute;
    left: 5px;
    top: 4px;
    display: inline-block;
    background: url(/img/close-fancy-bg.png) 0 0 no-repeat; } }

#search_block_top.active-search:before {
  visibility: visible;
  opacity: 1; }

#search_block_top.active-search #searchbox {
  visibility: visible; }

#search_block_top.active-search #searchbox #search_query_top {
  opacity: 1;
  width: 100%;
  padding: 5px 50px 5px 20px;
  transition-delay: .1s; }

@media (max-width: 767px) and (min-width: 320px) {
  #search_block_top.active-search #searchbox #search_query_top {
    padding: 5px 28px 5px 8px; } }

#search_block_top.active-search #searchbox .button-search {
  opacity: 1;
  transition: .3s all ease;
  transition-property: opacity, color;
  transition-delay: .3s, 0s; }

#search_block_top.active-search #searchbox .search-close {
  opacity: 1;
  left: -62px;
  transition: .3s all ease;
  transition-property: opacity, left, color;
  transition-delay: .3s, .25s, 0s; }

@media (max-width: 767px) {
  #search_block_top.active-search #searchbox .search-close {
    left: -50px; } }

.ac_results {
  padding: 20px !important;
  margin-top: 42px;
  border: none !important; }

.ac_results ul {
  overflow: hidden;
  clear: both; }

.ac_results li {
  padding: 15px !important;
  margin-top: 1px !important;
  overflow: hidden !important;
  cursor: pointer !important;
  transition: all .15s ease; }

.ac_results .name {
  display: block;
  font: 16px/26px "Open Sans", sans-serif;
  margin: 0; }

.ac_odd {
  background-color: #f8f8f8 !important; }

.ac_over {
  background-color: #eaeaea !important;
  color: inherit !important; }
